export const CONFIG = {
  cognitoUserPoolId: 'us-west-2_XWcnWx3Mw',
  userPoolWebClientId: '3p5uk7inbp03lse1pkubkbnsla',
  cognitoIdentityPoolId: 'us-west-2:331ecf01-6b84-4855-ac89-2d56fe129ec9',
  awsRegion: 'us-west-2',
  documentsUploadsBucket: 'contractor-documents-qa',
  userUploadsBucket: 'contractor-userdata-qa',

  baseApiUrl: 'https://www.api.contractor.eparconnectserver.com/qa-',
  apiStage: 'qa',
  trackingId:"UA-164108890-2",
}
